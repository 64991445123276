<template>
  <div class="content">
    <section class="listing-property">
      <div class="mobile-search">
        <div class="container-fluid plr-80">
          <b-form-input
            v-model="location.destination"
            placeholder="Search Location"
            class="search-icon"
            autocomplete="off"
            id="search-form-mobile"
            @keypress.enter="findPlaces"
          ></b-form-input>
        </div>
        <div class="container-fluid plr-80">
          <date-picker
            class="w-100 mt-3"
            v-model="dateRange"
            range
            value-type="format"
            format="YYYY-MM-DD"
            :placeholder="`${$t('listing.checkIn')} - ${$t(
              'listing.checkOut'
            )}`"
          ></date-picker>
          <b-form-select
            v-model="selected_adults"
            :options="adult_options"
            class="input mt-2"
          >
          </b-form-select>
          <button @click="findPlaces" class="btn my-2 btn-block btn-main">
            {{ $t("listing.search") }}
          </button>
          <div v-if="destination" class="my-3">
            <p v-if="fetching">
              {{ destination }}: {{ $t("listing.findingDeals") }}
            </p>
            <p v-else>
              {{ destination }}: {{ properties.totalElements }}
              {{ $t("listing.dealsFound") }}
            </p>
          </div>
        </div>
      </div>

      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-md-4 col-lg-3 listing-left-sec">
            <div class="search-wrapper custom-calender">
              <h2>{{ $t("listing.search") }}</h2>
              <div class="form-outer">
                <b-row class="mb-20">
                  <b-col md="12">
                    <label for="input-small">{{
                      $t("listing.destination")
                    }}</label>
                  </b-col>
                  <b-col md="12">
                    <b-form-input
                      id="search-form"
                      autocomplete="off"
                      :placeholder="$t('listing.searchLocation')"
                      class="input"
                      v-model="location.destination"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <label for="input-small">{{ $t("listing.checkIn") }}</label>
                    <b-form-input
                      id="search-form"
                      :placeholder="$t('listing.checkIn')"
                      class="input"
                      readonly
                      :value="date.checkIn"
                      @click="showCheckIn = true"
                    ></b-form-input>
                    <date-picker
                      :open.sync="showCheckIn"
                      v-model="dateRange"
                      value-type="format"
                      format="YYYY-MM-DD"
                      type="date"
                      range
                      placeholder="Select date range"
                    ></date-picker>
                  </b-col>
                  <b-col md="12">
                    <label for="input-small">{{
                      $t("listing.checkOut")
                    }}</label>
                    <b-form-input
                      id="search-form"
                      :placeholder="$t('listing.checkOut')"
                      class="input"
                      @click="showCheckOut = true"
                      :value="date.checkOut"
                      readonly
                    ></b-form-input>
                    <date-picker
                      :open.sync="showCheckOut"
                      v-model="dateRange"
                      value-type="format"
                      format="YYYY-MM-DD"
                      type="date"
                      range
                      placeholder="Select date range"
                    ></date-picker>
                  </b-col>
                </b-row>
                <b-row class="mb-20">
                  <b-col md="12">
                    <label for="input-small">{{
                      $t("listing.noOfAdults")
                    }}</label>
                    <b-form-select
                      v-model="selected_adults"
                      :options="adult_options"
                      class="input"
                    >
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row class="submit-wrapper">
                  <b-col md="12">
                    <b-button class="" @click="findPlaces">{{
                      $t("listing.search")
                    }}</b-button>
                  </b-col>
                </b-row>
              </div>
            </div>

            <!-- <div class="filter-wrapper">
              <div class="budget col-12">
                <h4>Your budget (per night)</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>Type of place</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Entire place</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Hotel</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Hostel</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Private room</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>Popular filter</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg"
                      >Breakfast included</b-form-checkbox
                    >
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Hotels</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Very good: 8+</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Less that 3 km</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Double bed</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
            </div> -->
          </div>
          <div class="col-md-8 col-lg-9 listing-right-sec">
            <div class="row align-items-center top-bar">
              <div class="col-6">
                <div v-if="destination">
                  <h2 v-if="fetching">
                    {{ destination }}: {{ $t("listing.findingDeals") }}
                  </h2>
                  <h2 v-else>
                    {{ destination }}: {{ properties.totalElements }}
                    {{ $t("listing.dealsFound") }}
                  </h2>
                </div>
              </div>
              <div
                class="col-6 text-right"
                v-if="this.location.latitude && this.location.longitude"
              >
                <p class="position-relative float-right">
                  <img src="../assets/images/map.png" alt="" />
                  <a
                    :href="`https://www.google.com/maps?q=${this.location.latitude},${this.location.longitude}`"
                    target="_blank"
                    class="show-on-map"
                    >{{ $t("listing.showOnMap") }}</a
                  >
                </p>
              </div>

              <!-- <div class="col-12">
                <div class="tab-sorting">
                  <a href="#" class="active">Our recomendations</a>
                  <a href="#">Hotels </a>
                  <a href="#">Entire homes & apartments</a>
                  <a href="#"
                    >Price<i class="fa fa-caret-down" aria-hidden="true"></i
                  ></a>
                  <a href="#" class="border-none"
                    >Reviews<i class="fa fa-caret-down" aria-hidden="true"></i
                  ></a>
                </div>
              </div> -->
            </div>

            <Spinner v-if="fetching" />

            <div style="min-height: 40vh" v-else>
              <div
                v-for="property of paginatedProperties"
                :key="property.id"
                class="row hotel-block"
              >
                <div class="col-md-4 col-lg-5 col-6">
                  <div class="hotel-slider">
                    <b-carousel
                      id="carousel-fade"
                      :interval="3000"
                      style="text-shadow: 0px 0px 2px #000"
                      fade
                      indicators
                      img-width="1024"
                      img-height="480"
                      ><div @click="openProperty(property.id)">
                        <template v-if="property.media.length">
                          <div v-for="item of property.media" :key="item.id">
                            <b-carousel-slide
                              :img-src="item.url"
                            ></b-carousel-slide>
                          </div>
                        </template>
                        <template v-else>
                          <b-carousel-slide
                            v-for="i of 2"
                            :key="i"
                            img-src="/images/property-default-image.jpg"
                          ></b-carousel-slide>
                        </template>
                      </div>
                    </b-carousel>
                  </div>
                </div>
                <div class="col-md-8 col-lg-7 col-6 hotelTxtInner">
                  <div class="hotel-txt">
                    <div class="hotel-rating row">
                      <div class="col-md-8">
                        <div @click="openProperty(property.id)">
                          <img
                            src="../assets/images/chevron-right-large.png"
                            alt=""
                            class="posIcon"
                          />
                        </div>
                        <p>{{ property.name }}</p>
                        <h4 class="d-flex align-items-center">
                          <span
                            ><div
                              @click="openProperty(property.id)"
                              style="color: #444; cursor: pointer"
                            >
                              {{ property.name }}
                            </div></span
                          >
                          <StarRating :value="ratingValue(property.ratingValue)" />
                        </h4>
                        <div class="access d-flex">
                          <li v-if="property.addresses">
                            <a
                              :href="`https://www.google.com/maps?q=${property.geoCode.latitude},${property.geoCode.longitude}`"
                              target="_blank"
                              >{{ property.addresses[0] }}</a
                            >
                          </li>
                          <li>
                            <a
                              :href="`https://www.google.com/maps?q=${property.geoCode.latitude},${property.geoCode.longitude}`"
                              target="_blank"
                              >{{ $t("listing.showOnMap") }}</a
                            >
                          </li>
                          <!-- <li>2.6 km from centre</li>
                          <li>Metro access</li> -->
                        </div>
                      </div>
                      <div class="col-md-4 d-flex review">
                        <h5 class="text-center">
                          {{ getTranslatedRating(property.ratingValue) }}
                        </h5>
                        <span class="rating">{{ ratingValue(property.ratingValue) }}</span>
                      </div>
                    </div>

                    <!-- <div class="hotel-room row">
                      <div class="col-md-8">
                        <h6>
                          Deluxe King room
                          <span>1 extra-large double bed</span>
                        </h6>
                      </div>
                      <div class="col-md-4 justify-content-end">
                        <p>29 nights, 2 adults</p>
                        <h4>€ 1,000</h4>
                      </div>
                    </div> -->

                    <!-- <div class="hotel-request d-flex">
                      <a href="#">Free cancellation</a>
                      <a href="#">Need request</a>
                    </div> -->

                    <div
                      class="availability justify-content-end d-flex align-items-center"
                    >
                      <b-button @click="openProperty(property.id)">{{
                        $t("listing.viewDetails")
                      }}</b-button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="properties.content && properties.content.length"
                class="row pagination-outer align-items-center"
              >
                <div class="col-6 pagination">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="properties.totalElements"
                    :per-page="perPage"
                  ></b-pagination>
                </div>
                <div class="col-6 text-right sort">Showing 1 - 25</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import Spinner from "./Widgets/Spinner.vue";
import StarRating from "./Widgets/StarRating.vue";
import { initPlaces, removeInstance } from "../helpers/map";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "PropertyListing",
  components: {
    Spinner,
    StarRating,
    DatePicker,
  },

  data() {
    return {
      dateRange: null,
      showCheckIn: false,
      showCheckOut: false,
      location: {
        destination: "",
        latitude: 0,
        longitude: 0,
      },
      date: {
        checkIn: null,
        checkOut: null,
      },
      destination: "",
      currentPage: 1,
      perPage: 10,
      selected_adults: "null",
      adult_options: [
        {
          text: "1 Adult",
          value: null,
        },
        {
          text: "2 Adults",
          value: 2,
        },
        {
          text: "3 Adults",
          value: 3,
        },
        {
          text: "4 Adults",
          value: 4,
        },
      ],
      selected_childrens: "null",
      children_options: [
        {
          text: "Children",
          value: null,
        },
        {
          text: "1 Child",
          value: 1,
        },
      ],
      selected_rooms: "null",
      rooms_options: [
        {
          text: "1 Room",
          value: null,
        },
        {
          text: "2 Rooms",
          value: 2,
        },
        {
          text: "3 Rooms",
          value: 3,
        },
        {
          text: "4 Rooms",
          value: 4,
        },
      ],
      selected: "",
      options: [],
      search: "",
    };
  },
  mounted() {
    ["search-form", "search-form-mobile"].forEach((id) => {
      initPlaces(id, ({ latitude, longitude, destination }) => {
        this.location.latitude = latitude;
        this.location.longitude = longitude;
        this.location.destination = destination;
      });
    });
  },
  created() {
    const { latitude, longitude, destination } = this.$route.query;
    if (latitude && longitude) {
      this.location.latitude = latitude;
      this.location.longitude = longitude;
      this.destination = destination;
      this.searchProperties({
        latitude,
        longitude,
        radius: 20,
        page: this.currentPage,
        perPage: this.perPage,
      });
    }
  },
  computed: {
    ...mapState("property", ["properties", "fetching"]),

    paginatedProperties() {
      if (!this.properties?.content) return [];
      const properties = JSON.parse(JSON.stringify(this.properties.content));
      return properties.map(({ content: property }) => {
        property.ratingValue = property.googleReviews?.googleRating || 0;

        if (property.media) {
          property.media
            .map((m) => {
              m.order = m.sortOrderMain || Number.MAX_VALUE;
              return m;
            })
            .sort((a, b) => a.order - b.order)
            .slice(0, 4);
        }
        return property;
      });
    },
  },
  methods: {
    ...mapActions("property", ["searchProperties"]),
    ratingValue(value) {
      return parseFloat(value > 0 ? (value / 2).toFixed(1) : value);
    },
    async findPlaces() {
      const { latitude, longitude, destination } = this.location;
      if (latitude && longitude) {
        const { latitude: lat, longitude: long } = this.$route.query;
        if (latitude != lat || longitude != long) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              latitude,
              longitude,
              destination,
            },
          });
        }
        this.destination = destination;
        this.searchProperties({
          latitude,
          longitude,
          radius: 20,
          page: this.currentPage,
          perPage: this.perPage,
        });
      }
    },
    dateFunction() {
      var week_name = document.getElementsByClassName(
        "vhd__datepicker__week-name"
      );
      for (var i = 0; i < week_name.length; i++) {
        week_name[i].innerHTML = week_name[i].innerHTML.substr(0, 4);
      }
    },
    populateDate(e, checkIn, checkOut) {
      this.date.checkIn = moment(checkIn).format("DD-MM-YYYY");
      this.date.checkOut = moment(checkOut).format("DD-MM-YYYY");
    },
    openProperty(id) {
      const { checkIn, checkOut } = this.date;
      this.$router.push(
        checkIn && checkOut
          ? `/listing/${id}?checkIn=${checkIn}&checkOut=${checkOut}`
          : `/listing/${id}`
      );
    },
    getRatingTranslationKey(score) {
      if (score >= 9.5 && score <= 10) {
        return "rating.exceptional";
      } else if (score >= 9 && score < 9.5) {
        return "rating.wonderful";
      } else if (score >= 8 && score < 9) {
        return "rating.veryGood";
      } else if (score >= 7 && score < 8) {
        return "rating.good";
      } else if (score >= 6 && score < 7) {
        return "rating.pleasant";
      } else if (score >= 5 && score < 6) {
        return "rating.reviewScore";
      } else if (score >= 4 && score < 5) {
        return "rating.passable";
      } else if (score >= 3 && score < 4) {
        return "rating.poor";
      } else if (score >= 2 && score < 3) {
        return "rating.veryPoor";
      } else if (score >= 1 && score < 2) {
        return "rating.disappointing";
      } else {
        return "rating.noRating";
      }
    },
    getTranslatedRating(score) {
      const key = this.getRatingTranslationKey(score);
      return this.$t(key);
    },
  },
  beforeDestroy() {
    removeInstance();
  },
  watch: {
    currentPage() {
      const { latitude, longitude } = this.location;
      this.searchProperties({
        latitude,
        longitude,
        radius: 20,
        page: this.currentPage,
        perPage: this.perPage,
      });
      window.scrollTo(0, 0);
    },
    dateRange(value) {
      if (value) {
        this.date.checkIn = value[0];
        this.date.checkOut = value[1];
      } else {
        this.date.checkIn = null;
        this.date.checkOut = null;
      }
    },
  },
};
</script>
